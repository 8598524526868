<template>
  <div>
    <b-sidebar
      id="sidebar-right-edit"
      sidebar-class="sidebar-lg"
      title="Edit User"
      backdrop
      :no-close-on-backdrop="true"
      right
      shadow
      no-header-close
    >
      <template>
        <validation-observer
          ref="refFormObserver"
        >
          <b-form
            class="p-2"
            @submit.enter.prevent=""
            @reset.prevent="resetForm"
          >
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required"
            >
              <b-form-group
                label="First Name"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="formData.profile.first_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required"
            >
              <b-form-group
                label="Last Name"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="formData.profile.last_name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="First Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mobile Number -->
            <validation-provider
              #default="validationContext"
              name="Mobile Number"
              rules="required"
            >
              <b-form-group
                label="Mobile Number"
                label-for="mobile-number"
              >
                <b-form-input
                  id="mobile-number"
                  v-model="formData.phone_number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!--! Password -->
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="formData.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false:null"
                  name="login-password"
                  placeholder="Password"
                />

                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="updateUser()"
              >
                Save
              </b-button>
              <b-button
                ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="cancel()"
              >
                Back
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      show: true,
      passwordFieldType: 'password',
      formData: {
        profile: {
          first_name: '',
          last_name: '',
        },
        email: '',
        phone_number: '',
        password: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    id() {
      this.getUser()
    },
  },
  methods: {
    async getUser() {
      await this.$http.get(`http://apptest.muslimdo.com/api/users/internalops/user/${this.id}`).then(res => {
        this.formData = res.data.data
        this.formData.profile = res.data.data.profile
      })
    },
    updateUser() {
      this.$http.post(`http://apptest.muslimdo.com/api/users/internalops/user/${this.id}`, this.formData).then(() => {
        this.cancel()
        this.formData = {}
        this.successfulOperationAlert('User is updated successfully')
        this.$parent.$refs.table.refresh()
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    cancel() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
    successfulUpdatingAlert() {
      this.$swal(
        'Added!',
        'User has been Updated.',
        'success',
      )
    },
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      successfulOperationAlert,
      showErrors,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.form-main {
    width: 79%;
    margin: auto;
}
</style>
