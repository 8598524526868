<template>
  <div>
    <b-card
      no-body
    >
      <!-- Table Top -->
      <b-row
        class="m-2"
      >
        <b-col class="text-right">
          <b-button
            v-b-toggle.sidebar-right
            variant="primary"
          >
            <span class="text-nowrap">Add User</span>
          </b-button>
        </b-col>

      </b-row>
      <b-table
        ref="table"
        :no-provider-sorting="true"
        :items="getUsers"
        :busy.sync="isBusy"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        class="position-relative"
        responsive="lg"
        primary-key="id"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo"
                :text="avatarText(data.item.name)"
              />
            </template>
            <b-link
              :to="{ name: 'view-user', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">#{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          {{ data.item.phone_number }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'view-user', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> View </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-toggle.sidebar-right-edit
              @click="sendInfo(data.item.id)"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="confirmDelete(deleteEndPoint,data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import deleteRecord from '@/common/compositions/common/deleteRecord'

export default {
  props: {
    getUsers: { type: Function, default: null },
    currentPage: { type: Number, default: null },
    perPage: { type: Number, default: null },
  },
  data() {
    return {
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'phone', sortable: true },
        { key: 'actions' },
      ],
      isBusy: false,
      deleteEndPoint: 'https://users-microsevice.muslimdo.com/api/internalops/user/',
    }
  },
  setup() {
    const { confirmDelete, table } = deleteRecord()
    return {
      confirmDelete, table,
    }
  },
  methods: {
    avatarText,
    sendInfo(id) {
      this.$emit('update:selected-id', id)
    },
  },
}
</script>
<style lang='scss' scoped>
</style>
