<template>
  <div>
    <b-row>
      <b-col cols="12">
        <user-list-edit
          :id="selectedID"
        />
      </b-col>

      <b-col cols="12">
        <user-table
          v-bind="{getUsers,currentPage,perPage}"
          :selected-id.sync="selectedID"
        />

        <pagination
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page.sync="currentPage"
        />

        <user-list-add-new />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserListEdit from '@/common/components/PortalUsers/EditUser.vue'
import UserTable from '@/common/components/PortalUsers/UserTable.vue'
import UserListAddNew from '@/common/components/PortalUsers/AddUser.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  components: {
    UserListEdit,
    UserTable,
    UserListAddNew,
    Pagination,
  },

  data() {
    return {
      selectedID: 0,
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      loading: true,
    }
  },
  methods: {
    getUsers() {
      return this.$http.get(`https://usewrs-microsevice.muslimdo.com/api/internalops/user/list/${this.currentPage}`)
        .then(res => {
          const items = res.data.data.data
          this.perPage = res.data.data.pagination.per_page
          this.totalRows = res.data.data.pagination.total
          return items
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">

</style>
